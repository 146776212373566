import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { companyService } from '../../Services/companyService';
import { userConektaService } from '../../Services/Fintechs/usersConektaService';
import { conektaService } from '../../Services/Fintechs/conektaService';
import { companyPlanSubscriptionService } from '../../Services/companyPlanSubscriptionService';
import { useAuth } from '../../Context/auth';

import { AzureBlobStorageService } from '../../Services/azureBlobStorageService';
import swal from 'sweetalert';
import { paymentFrecuencyService } from '../../Services/paymentFrecuencyService';

const defaultImageSrc = '/assets/images/logoaxify.jpg';
const initialFieldValues = {    
    imageSrc: defaultImageSrc,
    imageFile: null
};

const CompanyEdit = () => {
    const navigate =  useNavigate();  
    const auth = useAuth();     
    
    const [useStatecompany, setUsestateCompany] = useState({
        id: auth?.user.companyId,
        name:'',
        email:'',
        phone:'',
        externalRefAntad:'',
        logofileName:'',
        conekta_Customer: ''
    });  

    const [values, setValues] = useState(initialFieldValues);

    const [stateTokenApiFintech, setStateTokenFintech] = useState('');
   
    const [stateCardDefaultInfo, setstateCardDefaultInfo] = useState({
        id: ''
    });

    const [hiddenSpinner1, sethiddenSpinner1] = useState(false);

    const opc1 = 'card bg-light mb-3';
    const opc2 = 'card text-white bg-success mb-3';

    const [stateClassOption1, setStateClassOption1] = useState(opc1);
    const [stateClassOption2, setStateClassOption2] = useState(opc1);
    const [stateClassOption3, setStateClassOption3] = useState(opc1);
    const [stateClassOption4, setStateClassOption4] = useState(opc1);

    useEffect(() => {
        InitProcessCompany();
    }, []);

    const InitProcessCompany = async () => {
        sethiddenSpinner1(true);

        let companyResponse = await companyService.getById(auth?.user.token, auth?.user.companyId);
        SetClassForCardCurrenSubscription(+companyResponse.data.subscriptionPlan);
        setUsestateCompany(companyResponse.data);  
        
        const tokenfintech = await GetTokenFromApFintechs();
        setStateTokenFintech(tokenfintech);

        if (companyResponse.data.conekta_Customer == undefined || companyResponse.data.conekta_Customer == '' || companyResponse.data.conekta_Customer == null) {
            const requestCreateConektaCustomer = {
                "name": companyResponse.data.name,
                "email": companyResponse.data.email,
                "phone": companyResponse.data.phone
            };        
            var conektaCustomerResponse = await conektaService.postCreateCustomer(tokenfintech, requestCreateConektaCustomer);

            if (conektaCustomerResponse) {
                const sauronCompanyRequest = {
                    id: auth?.user.companyId,
                    conekta_Customer: conektaCustomerResponse.data.id,
                };
                const sauronCompanyResponse = await companyService.putConektaCustomer(auth?.user.token, auth?.user.companyId, sauronCompanyRequest);
                companyResponse = await companyService.getById(auth?.user.token, auth?.user.companyId);                
            }
        }

        //console.log();

        var conektacustomer = await conektaService.getConektaCustomerById(tokenfintech, companyResponse.data.conekta_Customer);

        //console.log(conektacustomer);

        if(conektacustomer.data.payment_sources != null) {
            const payemtnsourceList = conektacustomer.data.payment_sources.data;
            const paymentsourceDefault = payemtnsourceList.find(item => item.id == conektacustomer.data.default_payment_source_id);
    
            //console.log(paymentsourceDefault);
    
            const paymentSourceTmp = {
                id: paymentsourceDefault.id,
                brand: paymentsourceDefault.brand,
                card_type: paymentsourceDefault.card_type,
                exp_month: paymentsourceDefault.exp_month,
                exp_year: paymentsourceDefault.exp_year,
                last4: paymentsourceDefault.last4,
            };
            setstateCardDefaultInfo(paymentSourceTmp);    
        }
        //console.log(stateCardDefaultInfo);
        sethiddenSpinner1(false);
    }

    const onChangehandleChange = (event) => {
        const {name, value} = event.target;
        setUsestateCompany((prevState) => ({
            ...prevState,
            [name]: value
        }));        
    }

    const handleChangeImage = (e) => {
        if (e.target.files && e.target.files[0]){
            let imageFile = e.target.files[0];
            //console.log(imagefile);                    
            const reader = new FileReader();
            reader.onload = x => {
                setValues({
                    ...values,
                    imageFile,
                    imageSrc: x.target.result
                })
            };
            reader.readAsDataURL(imageFile);
        } else {
            setValues({
                ...values,
                imageFile: null,
                imageSrc: defaultImageSrc
            })
        }
    }

    const onClickbtnSaveUpdate = (e) => {
        e.preventDefault();

        companyService.put(auth?.user.token, auth?.user.companyId, useStatecompany);           

        if(values.imageFile != null) {
            const formData = new FormData();
            formData.append('imageName', useStatecompany.name.replace('.','').replace(' ',''));
            formData.append('imageFile', values.imageFile);

            AzureBlobStorageService.Upload(auth.user.token, formData);    
        } 

        navigate('/home', { replace: true });
    }

    const onClickbtnCancel = (e) => {
        e.preventDefault();

        navigate('/home', { replace: true });
    }

    const onClickBtnApplyEmp = (e) => {
        e.preventDefault();

        navigate('/CompanyUpdateSubscription/1', { replace: true });
    }

    const onClickBtnApplyPyme = (e) => {
        e.preventDefault();

        navigate('/CompanyUpdateSubscription/2', { replace: true });
    }

    const onClickBtnApplyGrouth = (e) => {
        e.preventDefault();

        navigate('/CompanyUpdateSubscription/3', { replace: true });
    }

    const onClickBtnApplyUnlimited = (e) => {
        e.preventDefault();

        navigate('/CompanyUpdateSubscription/4', { replace: true });
    }

    const onClickbtnDelete = (e, id) => {
        e.preventDefault();        

        //const conektacustomerTmp = useStatecompany.conekta_Customer;
        conektaService.deletePaymentMethod(stateTokenApiFintech, useStatecompany.conekta_Customer, id).then(() => {
            navigate('/home', { replace: true });
        });
    }

    const GetTokenFromApFintechs = async () => {
        const userForAuthenticate = {
            "Id": 0,
            "User": "soporte@axify.mx",
            "Password": "Axify2023",
            "Token": ""
        };
        const responseConektaUser = await userConektaService.post(userForAuthenticate);
        
        //console.log(responseConektaUser);
        
        const tokenconekta = responseConektaUser.data.token;

        return tokenconekta;
    }

    const SetClassForCardCurrenSubscription = (opc) => {
        setStateClassOption1(opc1);
        setStateClassOption2(opc1);
        setStateClassOption3(opc1);
        setStateClassOption4(opc1);

        switch(opc){
            case 1:
                setStateClassOption1(opc2);
                break;
            case 2:
                setStateClassOption2(opc2);
                break;
            case 3:
                setStateClassOption3(opc2);
                break;
            case 4:
                setStateClassOption4(opc2);
                break;

        }
        
    }

    return (
        <>
         <div className="container">
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Editar Compania</h2>
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>                
                <div className="row">                   
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-data-tab" data-toggle="pill" data-target="#pills-data" type="button" role="tab" aria-controls="pills-data" aria-selected="true">Datos</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button className="nav-link" id="pills-suscription-tab" data-toggle="pill" data-target="#pills-suscription" type="button" role="tab" aria-controls="pills-suscription" aria-selected="false">Membresía</button>
                        </li>                       
                    </ul>                 
                    <div className="tab-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-data" role="tabpanel" aria-labelledby="pills-data-tab">
                            <div className="row">
                                <div className="col">
                                    <div className='row'>
                                        <div className='col'>
                                            <label htmlFor="name" className="form-label">Nombre</label>
                                            <input className={`form-control ${useStatecompany.name?.length > 0 ? 'is-valid':'is-invalid'}`} 
                                                placeholder="" required="" 
                                                id="name"
                                                name="name"
                                                type="text"
                                                onChange={onChangehandleChange}
                                                value={useStatecompany.name}            
                                            />          
                                            <div className="invalid-feedback">Es requerido el nombre!</div>   
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input className={`form-control ${useStatecompany.email?.length > 0 ? 'is-valid':'is-invalid'}`} 
                                                placeholder=""  required="" 
                                                id="email"
                                                name="email"
                                                type="text"
                                                onChange={onChangehandleChange}
                                                value={useStatecompany.email}       
                                            />
                                            <div className="invalid-feedback">Es requerido el email!</div>  
                                        </div>
                                    </div>  
                                    <div className='row'>
                                        <div className='col'>
                                            <label htmlFor="phone" className="form-label">Telefono</label>
                                            <input className={`form-control ${useStatecompany.phone?.length > 0 ? 'is-valid':'is-invalid'}`} 
                                                placeholder=""  required="" 
                                                id="phone"
                                                name="phone"
                                                type="text"
                                                onChange={onChangehandleChange}
                                                value={useStatecompany.phone}       
                                            />
                                            <div className="invalid-feedback">Es requerido el telefono!</div>  
                                        </div>
                                    </div>              
                                    <div className='row'>
                                        <div className='col'>
                                            <label htmlFor="externalRefAntad" className="form-label">Referencia antad</label>
                                            <input className={`form-control ${useStatecompany.externalRefAntad?.length > 0 ? 'is-valid':'is-invalid'}`} 
                                                placeholder=""  
                                                id="externalRefAntad"
                                                name="externalRefAntad"
                                                type="text"
                                                onChange={onChangehandleChange}
                                                value={useStatecompany.externalRefAntad}              
                                            />
                                            <div className="invalid-feedback">Es requerida la ref antad!</div> 
                                        </div>
                                    </div>                       
                                </div>
                                <div className="col">         
                                    <div className="card" style={{width: "18rem;"}}>
                                        <img src={values.imageSrc} className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <div className='form-group'>
                                                <input type="file" className="form-control-file" id="filename" name="filename" onChange={handleChangeImage}/> 
                                            </div> 
                                        </div>
                                    </div>                     
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <button style={{backgroundColor:'#FD9913', color:'white'}} type="submit" className="w-100 btn btn-success" 
                                        onClick={e => onClickbtnSaveUpdate(e)}>Actualizar compañia</button>    
                                </div>
                                <div className='col'>
                                    <button style={{backgroundColor:'#A962DA', color:'white'}} type="button" className="w-100 btn btn-secondary" onClick={e => onClickbtnCancel(e)}>Cancelar</button>                        
                                </div>                        
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-suscription" role="tabpanel" aria-labelledby="pills-suscription-tab">
                            <div className='row'>
                                <div className='col-2'></div>
                                <div className='col-8' style={{textAlign: 'center'}}>
                                    <h4>ELIGE TU PLAN MENSUAL:</h4>
                                </div>
                                <div className='col-2'></div>
                            </div>
                            <div className='row'>
                                <div className='col-2'></div>
                                <div className='col-8' style={{textAlign: 'center'}}>
                                    {/* <h5>Cambiar a anual (Obtén 2 meses gratis)</h5> */}
                                </div>
                                <div className='col-2'></div>
                            </div>
                            <br></br>
                            <br></br>
                            <div className='row'>
                                <div className='col'>
                                    <div className={stateClassOption1}>
                                        <div className="card-header">$350 mxn Emprendedor</div>
                                        <div className="card-body">
                                            <h5 className="card-title">1 Punto de venta</h5>
                                            <p className="card-text"> 3.6% + iva por transacción.</p>
                                            <p className="card-text">1 mes gratis</p>
                                            <p className="card-text">Costo al usuario $30.00 MXN por pago </p>
                                            <button className="btn btn-primary" onClick={onClickBtnApplyEmp}>aplicar</button>
                                        </div>
                                    </div>                                        
                                </div>
                                <div className='col'>
                                    <div className={stateClassOption2}>
                                        <div className="card-header">$990.00 MXN PYME</div>
                                        <div className="card-body">
                                            <h5 className="card-title">3 Puntos de venta</h5>
                                            <p className="card-text">3.6% + iva por transacción.</p>
                                            <p className="card-text">1 mes gratis</p>
                                            <p className="card-text">Costo al usuario $30.00 MXN por pago </p>
                                            <button className="btn btn-primary" onClick={onClickBtnApplyPyme}>aplicar</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className={stateClassOption2}>
                                        <div className="card-header">$2,450.00 MXN GROWTH</div>
                                        <div className="card-body">
                                            <h5 className="card-title">10 Puntos de venta</h5>
                                            <p className="card-text">3.6% por transacción.</p>
                                            <p className="card-text">1 mes gratis</p>
                                            <p className="card-text">Costo al usuario $30.00 MXN por pago </p>
                                            <button className="btn btn-primary" onClick={onClickBtnApplyGrouth}>aplicar</button>
                                        </div>
                                    </div>
                                </div>                                    
                                <div className='col'>
                                    <div className={stateClassOption4}>
                                        <div className="card-header">$14,000.00 MXN ENTERPRISE</div>
                                        <div className="card-body">
                                            <h5 className="card-title">Unlimited</h5>
                                            <p className="card-text">3.6% por transacción.</p>
                                            <p className="card-text">1 mes gratis</p>
                                            <p className="card-text">Costo al usuario $30.00 MXN por pago </p>
                                            <button className="btn btn-primary" onClick={onClickBtnApplyUnlimited}>aplicar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>                                
                            <br></br>
                            <div className='row'>
                                <div className='col-2'></div>
                                <div className='col-8' style={{textAlign: 'center'}}>
                                    <h4>FORMA DE PAGO:</h4>
                                </div>
                                <div className='col-2'></div>
                            </div>
                            <br></br>
                            <div className='row'>
                                <div className='col'>
                                    { hiddenSpinner1 &&
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden"></span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>            
                            <br></br>                    
                            <div className='row'>
                                <div className='col'>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Tipo Tarjeta</th>
                                            <th scope="col">Mes Exp.</th>
                                            <th scope="col">Año Exp.</th>
                                            <th scope="col">Ultimos4</th>
                                            <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <th scope="row">{stateCardDefaultInfo.brand}</th>
                                            <td>{stateCardDefaultInfo.card_type}</td>
                                            <td>{stateCardDefaultInfo.exp_month}</td>
                                            <td>{stateCardDefaultInfo.exp_year}</td>
                                            <td>{stateCardDefaultInfo.last4}</td>
                                            <td>
                                                {
                                                    stateCardDefaultInfo.id !== '' ?
                                                        <button style={{backgroundColor:'#FD9913', color:'white'}} type="button" className="btn btn-warning" onClick={e => onClickbtnDelete(e, stateCardDefaultInfo.id)}>Eliminar</button>
                                                        : null
                                                }
                                                
                                            </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>                            
                            <br></br>
                            <p>No se realizarán cargos hoy.</p>
                            <p>
                                Procesaremos un cargo a la tarjeta registrada cada mes conforme al plan de suscripción seleccionado excepto para el plan emprendedor, que
                                únicamente se cobrará por el porcentaje causado en cada transacción más IVA.
                            </p>
                        </div>
                    </div>                
                </div>                     
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>                
            </div>   
        </>
    );
}

export default CompanyEdit;






// <div className='row'>
//                                     <div className='col'>
//                                         <div className="form-check" style={{textAlign: 'center'}}>
//                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"                                                
//                                                 value='0'
//                                                 checked={stateRadioBntSelected === '0'}
//                                                 onChange={handleChangechecked}/>
//                                             <label className="form-check-label" htmlFor="flexRadioDefault1">$0.00 Emprendedor</label>
//                                             <br></br>
//                                             <label className="form-check-label" htmlFor="flexRadioDefault1">1 Punto de venta</label>
//                                             <br></br>
//                                             <label className="form-check-label" htmlFor="flexRadioDefault1">3.2% por transacción</label>
//                                         </div>
//                                     </div>
//                                     <div className='col' style={{textAlign: 'center'}}>
//                                         <div class="form-check">
//                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"                                                
//                                                 value='1'
//                                                 checked={stateRadioBntSelected === '1'}
//                                                 onChange={handleChangechecked}/>
//                                             <label className="form-check-label" htmlFor="flexRadioDefault1">$321.00 MXN PYME</label>
//                                             <br></br>
//                                             <label className="form-check-label" htmlFor="flexRadioDefault1">10 Puntos de venta</label>
//                                             <br></br>
//                                             <label className="form-check-label" htmlFor="flexRadioDefault1">3% por transacción</label>
//                                         </div>
//                                     </div>                                
//                                 </div>
//                                 <br></br>
//                                 <div className='row'>
//                                     <div className='col'>
//                                         <div className="form-check" style={{textAlign: 'center'}}>
//                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
//                                                 value='2'
//                                                 checked={stateRadioBntSelected === '2'}
//                                                 onChange={handleChangechecked}/>
//                                             <label className="form-check-label" htmlFor="flexRadioDefault1">$879.22 MXN GROWTH</label>
//                                             <br></br>
//                                             <label className="form-check-label" htmlFor="flexRadioDefault1">100 Puntos de venta</label>
//                                             <br></br>
//                                             <label className="form-check-label" htmlFor="flexRadioDefault1">2.75% por transacción</label>
//                                         </div>
//                                     </div>
//                                     <div className='col' style={{textAlign: 'center'}}>
//                                         <div className="form-check">
//                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
//                                                 value='3'
//                                                 checked={stateRadioBntSelected === '3'}
//                                                 onChange={handleChangechecked}/>
//                                             <label className="form-check-label" htmlFor="flexRadioDefault1">$6747.09 MXN ENTERPRISE</label>
//                                             <br></br>
//                                             <label className="form-check-label" htmlFor="flexRadioDefault1">Unlimited</label>
//                                             <br></br>
//                                             <label className="form-check-label" htmlFor="flexRadioDefault1">2.45% por transacción</label>
//                                         </div>
//                                     </div>
//                                 </div>