import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { businessService } from '../../Services/businessService';
import { businessTypeService } from '../../Services/businessTypeService';
import { paymentgatewayService } from '../../Services/paymentGatewayService';
import { paymentFrecuencyService } from '../../Services/paymentFrecuencyService';
import { fiscalRegimeService } from '../../Services/fiscalREgimeService';

import { useAuth } from '../../Context/auth';
import swal from 'sweetalert';


const CommerceAddEdit = () => {
    const auth = useAuth(); 

    const { id } = useParams();
    const isAddMode = !id;    
    const navigate =  useNavigate();   

    const radio01Ref = useRef(null);
    const radio02Ref = useRef(null);

    const validKeyForPayment = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
      ];

    const [stateBusiness, setStateBusiness] = useState({
        id: 0,
        businessName: '',
        tradeName: '',
        rfc: '',
        businessTypeId: '',
        contact: '',
        phone: '',
        email: '',
        paymentGatewayId: '0',       
        companyId: auth.user.companyId,
        externalReferenceAntad: '',
        ssoId: auth.user.id,
        website:'',
        typeRegime: '',
        taxregime:'',
        street:'',
        suburb:'',
        zip: ''
    });

    const [stateBusinessType, setStateBusinessType] = useState([]);
    const [statePaymentGateway, setStatePaymentGateway] = useState([]);
    const [statePaymentFrecuency, setStatePaymentFrecuency] = useState([]);

    const [stateEnableBtnSave, setStateEnableBtnSave] = useState('');

    const [ stateTypeRegime, setStateTypeRegime] = useState('');
    const [ stateFiscalRegime, setStateFiscalRegime] = useState([]);
           
    useEffect(() => {
        paymentgatewayService.getAllByCompanyId(auth.user.token, auth.user.companyId).then( response => {                  
            setStatePaymentGateway(response.data);
            stateBusiness.paymentGatewayId = response.data[0].id
        });       

        businessTypeService.getAll(auth.user.token).then( response => {                  
            setStateBusinessType(response.data);
        });       
        
        paymentFrecuencyService.getAll(auth.user.token).then( response => {                  
            setStatePaymentFrecuency(response.data);
        });       

        if (!isAddMode) {
            businessService.getById(auth.user.token, id).then(response => {              
                setStateBusiness(response.data);

                switch(response.data.typeRegime){
                    case 'pf':
                        radio01Ref.current.click();
                        break;
                    case 'pm':
                        radio02Ref.current.click();
                        break;
                }
            });      
        }
    }, []);

    const setPFPMFunction = () => {


    }

    const handleChangeRadio = (e) => {
        const { name, value } = e.target;
        
        switch(value){
            case 'pf':                
                fiscalRegimeService.GetAllFisicaAsync(auth.user.token).then(response => {
                    setStateFiscalRegime([]);
                    setStateFiscalRegime(response.data);
                    setStateTypeRegime('pf');
                    setStateBusiness((prevState) => ({
                        ...prevState,
                        'typeRegime': 'pf'
                    })); 
                });
                break;
            case 'pm':                
                fiscalRegimeService.GetAllMoralAsync(auth.user.token).then(response => {
                    setStateFiscalRegime([]);
                    setStateFiscalRegime(response.data);                    
                    setStateTypeRegime('pm');
                    setStateBusiness((prevState) => ({
                        ...prevState,
                        'typeRegime': 'pm'
                    })); 
                });
                break;
        }
    }   


    const onChangehandleChange = (event) => {
        const {name, value} = event.target;
        setStateBusiness((prevState) => ({
            ...prevState,
            [name]: value
        }));        

        validateFormData();
    }

    const onBlurhandleblur = () => {
        const validateResult = validateFormData();
    }
    
    const onClickbtnSaveUpdate = () => {
        const validateResult = validateFormData();       

        if(validateResult) {
            if (isAddMode) {           
                businessService.post(auth.user.token, stateBusiness);
            } else {
                businessService.put(auth.user.token, stateBusiness.id, stateBusiness);
            }
            // Redireccionar
            setTimeout(() => {
                navigate('/commerceList', { replace: true });    
            }, 1000);
        } else {
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Los datos esta mal!',
                footer: 'Favor de revisar los datos'
            });
        }
    }

    const onClickbtnCancel = (e) => {
        e.preventDefault();

        try {
          navigate('/commerceList', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    const validateFormData = () => {
        let validData = true;
        setStateEnableBtnSave('A');

        if (stateBusiness.businessName.length == 0 || stateBusiness.businessName == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        if (stateBusiness.tradeName.lenght == 0 || stateBusiness.tradeName == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        if (stateBusiness.rfc.lenght == 0 || stateBusiness.rfc == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        if (stateBusiness.businessTypeId == 0 || stateBusiness.businessTypeId == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        if (stateBusiness.contact.lenght == 0 || stateBusiness.contact == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        if (stateBusiness.phone.lenght == 0 || stateBusiness.phone == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        if (stateBusiness.email.lenght == 0 || stateBusiness.email == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        // if (stateBusiness.paymentGatewayId == 0 || stateBusiness.paymentGatewayId == '') {
        //     validData = false;
        //     setStateEnableBtnSave('');
        // }
        // if (stateBusiness.percentDeposit == 0 || stateBusiness.percentDeposit == '') {
        //     validData = false;
        //     setStateEnableBtnSave('');
        // }
        // if (stateBusiness.fracuencyPay == 0 || stateBusiness.fracuencyPay == '') {
        //     validData = false;
        //     setStateEnableBtnSave('');
        // }
        // if (stateBusiness.planPaymentDuration == 0 || stateBusiness.planPaymentDuration == '') {
        //     validData = false;
        //     setStateEnableBtnSave('');
        // }
        // if (stateBusiness.ammountoCancel == 0 || stateBusiness.ammountoCancel == '') {
        //     validData = false;
        //     setStateEnableBtnSave('');
        // }
        if (stateBusiness.externalReferenceAntad.length == 0 || stateBusiness.externalReferenceAntad == '') {
            validData = false;
            setStateEnableBtnSave('');
        }

        return validData;
    }


    return (
        <>                    
            <div className='container'>
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>{ isAddMode  ? 'Crear sucursal' : 'Editar sucursal' }</h2>
                    </div>
                </div>                
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>
                <div className='row'>
                    <div className='col'>                    
                        <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button className="nav-link active" id="generalinfo-tab" data-toggle="tab" data-target="#tab-generalinfo" type="button" role="tab" aria-controls="tab-generalinfo" aria-selected="true">Datos Generales</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button className="nav-link" id="fislcalinfo-tab" data-toggle="tab" data-target="#tab-filcalinfo" type="button" role="tab" aria-controls="tab-filcalinfo" aria-selected="false">Datos Fiscales</button>
                            </li>                       
                            <li class="nav-item" role="presentation">
                                <button className="nav-link" id="fislcalinfo-tab" data-toggle="tab" data-target="#tab-extrainfo" type="button" role="tab" aria-controls="tab-extrainfo" aria-selected="false">Datos Extras</button>
                            </li>
                        </ul>                 
                        <div className="tab-content" id="v-tabs-tabContent">
                            <div className="tab-pane fade show active" id="tab-generalinfo" role="tabpanel" aria-labelledby="tab-generalinfo">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="tradeName" className="form-label">Nombre comercial</label>
                                        <input type="text" className={`form-control ${stateBusiness.tradeName.length > 0 ? 'is-valid':'is-invalid'}`} 
                                            placeholder="" required="" 
                                            id="tradeName"
                                            name="tradeName"                                        
                                            onChange={onChangehandleChange}
                                            value={stateBusiness.tradeName}
                                            autoComplete="off"           
                                        />
                                        <div className="invalid-feedback">Es requerido el nombre comercial!</div>                                
                                    </div>    
                                    <div className="col">
                                        <label htmlFor="businessTypeId" className="form-label">Giro de negocio</label>
                                        <select
                                            className="form-control"
                                            id="businessTypeId"
                                            name="businessTypeId"                                            
                                            onChange={onChangehandleChange}                                                                              
                                            value={stateBusiness.businessTypeId}
                                        > 
                                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                                        {
                                            stateBusinessType.map((item, index) => <option key={index} value={item.id}>{item.businessTypeName}</option>)
                                        }
                                        </select>
                                    </div>       
                                </div>        
                                <br></br>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="contact" className="form-label">Contacto</label>
                                        <input type="text" className={`form-control ${stateBusiness.contact.length > 0 ? 'is-valid':'is-invalid'}`} 
                                            placeholder=""  required="" 
                                            id="contact"
                                            name="contact"                                        
                                            onChange={onChangehandleChange}
                                            value={stateBusiness.contact}
                                            autoComplete="off"                                                            
                                        />
                                        <div className="invalid-feedback">Es requerido el contacto!</div>                               
                                    </div>
                                    <div className="col">
                                        <label htmlFor="phone" className="form-label">Teléfono</label>
                                        <input type="text" className={`form-control ${stateBusiness.phone.length > 0 ? 'is-valid':'is-invalid'}`} 
                                            placeholder="" required="" 
                                            id="phone"
                                            name="phone"                                        
                                            onChange={onChangehandleChange}
                                            value={stateBusiness.phone}
                                            autoComplete="off"                                                            
                                        />
                                        <div className="invalid-feedback">Es requerido el telefono!</div>                                
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email" className={`form-control ${stateBusiness.email.length > 0 ? 'is-valid':'is-invalid'}`} 
                                            placeholder="" required="" 
                                            id="email"
                                            name="email"                                        
                                            onChange={onChangehandleChange}
                                            value={stateBusiness.email}
                                            autoComplete="off"                                                            
                                        />
                                        <div className="invalid-feedback">Es requerido el email!</div>                                
                                    </div>
                                    <div className='col'>
                                        <label htmlFor="website" className="form-label">Sitio web</label>
                                        <input type="text" className={`form-control ${stateBusiness.email.length > 0 ? 'is-valid':'is-invalid'}`} 
                                            placeholder="" required="" 
                                            id="website"
                                            name="website"                                        
                                            onChange={onChangehandleChange}
                                            value={stateBusiness.website}
                                            autoComplete="off"                                                            
                                        />
                                        <div className="invalid-feedback">sitio web requerido!</div> 
                                    </div>                  
                                </div> 
                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="street" className="form-label">Calle</label>
                                        <input type="text" className={`form-control ${stateBusiness.street.length > 0 ? 'is-valid':'is-invalid'}`} 
                                            placeholder="" required="" 
                                            id="street"
                                            name="street"                                        
                                            onChange={onChangehandleChange}
                                            value={stateBusiness.street}
                                            autoComplete="off"                                                            
                                        />
                                        <div className="invalid-feedback">calle requerida!</div> 
                                    </div>
                                    <div className='col'>
                                        <label htmlFor="suburb" className="form-label">Colonia</label>
                                        <input type="text" className={`form-control ${stateBusiness.suburb.length > 0 ? 'is-valid':'is-invalid'}`} 
                                            placeholder="" required="" 
                                            id="suburb"
                                            name="suburb"                                        
                                            onChange={onChangehandleChange}
                                            value={stateBusiness.suburb}
                                            autoComplete="off"                                                            
                                        />
                                        <div className="invalid-feedback">colonia requerida!</div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="zip" className="form-label">Codigo Postal</label>
                                        <input type="text" className={`form-control ${stateBusiness.zip.length > 0 ? 'is-valid':'is-invalid'}`} 
                                            placeholder="" required="" 
                                            id="zip"
                                            name="zip"                                        
                                            onChange={onChangehandleChange}
                                            value={stateBusiness.zip}
                                            autoComplete="off"                                                            
                                        />
                                        <div className="invalid-feedback">codigo postal requerido!</div> 
                                    </div>
                                    <div className='col'>                                       
                                    </div>
                                </div>              
                            </div>
                            <div class="tab-pane fade" id="tab-filcalinfo" role="tabpanel" aria-labelledby="tab-filcalinfo">                  
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="businessName" className="form-label">Razón social</label>
                                        <input type="text" className={`form-control ${stateBusiness.businessName.length > 0 ? 'is-valid':'is-invalid'}`} 
                                            placeholder="" required="" 
                                            id="businessName"
                                            name="businessName"                                        
                                            onChange={onChangehandleChange}
                                            onBlur={onBlurhandleblur}
                                            value={stateBusiness.businessName}
                                            autoComplete="off"                                  
                                        />
                                        <div className="invalid-feedback">Es requerida la razón social!</div>                                  
                                    </div>       
                                    <div className="col">
                                        <label htmlFor="rfc" className="form-label">Rfc</label>
                                        <input type="text" className={`form-control ${stateBusiness.rfc.length > 0 ? 'is-valid':'is-invalid'}`} 
                                            placeholder="" required="" 
                                            id="rfc"
                                            name="rfc"                                        
                                            onChange={onChangehandleChange}
                                            value={stateBusiness.rfc}
                                            autoComplete="off"                                                            
                                        />
                                        <div className="invalid-feedback">Es requerido el rfc!</div>                 
                                    </div>                            
                                </div>                                
                                <div className='row'>
                                    <div className='col'>
                                        <label className="form-label">tipo de Regimen fiscal</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="pfpmRadios" 
                                                id="exampleRadios1" value="pf"                                                 
                                                onClick={event => handleChangeRadio(event)}
                                                ref={radio01Ref}
                                               />
                                            <label className="form-check-label" for="exampleRadios1">
                                                Persona Fisica
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="pfpmRadios" 
                                                id="exampleRadios2" value="pm" 
                                                onClick={event => handleChangeRadio(event)}
                                                ref={radio02Ref}
                                                />
                                            <label className="form-check-label" for="exampleRadios2">
                                                Persona Moral
                                            </label>
                                        </div>                                        
                                    </div>
                                    <div className='col'>
                                        <label htmlFor="taxregime" className="form-label">Regimen fiscal</label>
                                        <select
                                            className="form-control"
                                            id="taxregime"
                                            name="taxregime"                                            
                                            onChange={onChangehandleChange}                                                                              
                                            value={stateBusiness.taxregime}
                                        > 
                                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                                        {
                                            stateFiscalRegime.map((item, index) => <option key={index} value={item.id}>{item.regimeFiscal + '-' + item.description}</option>)
                                        }
                                        </select>
                                    </div>
                                </div>                                                   
                            </div>
                            <div className="tab-pane fade" id="tab-extrainfo" role="tabpanel" aria-labelledby="tab-extrainfo">
                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="externalReferenceAntad" className="form-label">Referencia Externa Antad</label>
                                        <input type="text" className={`form-control ${stateBusiness.externalReferenceAntad.length > 0 ? 'is-valid':'is-invalid'}`}
                                            placeholder="" required="" 
                                            id="externalReferenceAntad"
                                            name="externalReferenceAntad"                                        
                                            onChange={onChangehandleChange}
                                            value={stateBusiness.externalReferenceAntad}
                                            autoComplete="off"                                                            
                                        />
                                        <div className="invalid-feedback">Es requerida la referencía externa de antad!</div>     
                                    </div>  
                                    <div className='col'>

                                    </div>  
                                </div>                              
                            </div>
                        </div>
                    </div> 
                </div>      
                <br></br>
                <div className='row'>
                    <div className='col'>
                        <button style={{backgroundColor:'#FD9913', color:'white'}} type="button" 
                            className="w-100 btn btn-success"
                            onClick={e => onClickbtnSaveUpdate(e)}
                            disabled={!stateEnableBtnSave}
                            >{isAddMode ? ' Agregar comercio ' : ' Actualizar comercio '}</button>    
                    </div>
                    <div className='col'>
                        <button style={{backgroundColor:'#0D6EFD', color:'white'}} type="button" 
                            class="w-100 btn btn-secondary" onClick={e => onClickbtnCancel(e)}>Cancelar</button>                        
                    </div>                        
                </div>  
            </div>                     
        </>
    )
}

export default CommerceAddEdit;