import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';


import base64 from 'react-native-base64';
import swal from 'sweetalert';
import { IpifyService } from '../../Services/api_ipify_org';
import {v4 as uuidv4} from 'uuid';

import { useAuth }from '../../Context/auth';
//import { businessService} from '../../Services/businessService';
//import { businessProductService } from '../../Services/businessProductService';

//import {pullApartRefService} from '../../Services/Cxc/PullApartService';
import { boromirUserService } from '../../Services/Boromir/boromirUserService';
import { boromirService } from '../../Services/Boromir/boromirService';
import { cxcService } from '../../Services/Cxc/cxcService';
import { salesService } from '../../Services/salesService';

import { Utils } from '../../Utils/utils';

const ServicePayment = () => {
    const { id, soid} = useParams();
    const auth = useAuth(); 

    const navigate =  useNavigate(); 

    const [stateRefApartOrPayment, setStateRefApartOrPayment] = useState('');
    const [stateConfirmRefApartOrPayment, setStateConfirmRefApartOrPayment] = useState('');
   
    const [stateExpiration, setStateExpiration] = useState();
    const [stateAmmount, setstateAmmount] = useState(0);
    const [statePayments, setstatePayments] = useState(0);
    const [stateBalance, setstateBalance] = useState(0);

    const [stateMyIp, setStateMyIp] = useState('');

    const [stateSeeAlertSuccess, setstateSeeAlertSuccess] = useState(false);
    const [stateSeeAlertWarning, setstateSeeAlertWarning] = useState(false);

    const [stateResponseBoromirPayment, setstateResponseBoromirPayment] = useState(
        {           
            "Data": {
                "Id": 0,
                "SaleDate": new Date,
                "Description": "",
                "Details": "",
                "Sku": "",
                "Ammount": 0,
                "AmmountPre": 0
            },
            "IsSuccess": false,
            "Meta": null,
            "Error": []           
        }
    );

    const [stateSaleid, setStateSaleId] = useState();
    const [stateAmmountB, setstateAmmountB] = useState(0);
    const [stateDescription, setstateDescrition] = useState('');
    const [stateDetail, setstateDetail] = useState('');    

    const [stateEnableBtnSearchRef, setStateEnableBtnSearchRef] = useState('A');
    const [stateEnableBtnApplyRef, setStateEnableBtnApplyRef] = useState('');
    const [stateEnableBtnPrintTicket, setStateEnableBtnPrintTicket] = useState('');

    const [hiddenSpinner1, sethiddenSpinner1] = useState(false);

    useEffect(() => {
        IpifyService.getIP().then((response) =>{
            setStateMyIp(response.ip);
        });
    }, []);  

    

    const onChangeRefApartOrPayment = (event)=> {
        setStateRefApartOrPayment(event.target.value);
    }    

    const onKeyDownRefApartOrPayment = (event) => {        
        // if (event.keyCode == 17 || event.keyCode == 86){
        //     console.log('ok');
        // } else {
        //     event.preventDefault();            
        // }
    }

    const onChangeConfirmRefApartOrPayment = (event) => {         
        setStateConfirmRefApartOrPayment(event.target.value);
    }

    const onKeyDownConfirmRefApartOrPayment = (event) => {        
        // if (event.keyCode == 17 || event.keyCode == 86){
        //     console.log('ok');
        // } else {
        //     event.preventDefault();            
        // }
    }

    const onClickBtnSearchOperation = () => {
        const operationType = stateRefApartOrPayment.substring(0, 1);
        const refTmp = stateRefApartOrPayment.substring(1, stateRefApartOrPayment.length);

        if (operationType === Utils.OperationTypeEnum.PaymentA || operationType === Utils.OperationTypeEnum.PaymentB) { 
            SearchPaymentProcess(refTmp);
        } else {
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'las referencía no es para realizar un apartado!',
                footer: 'Favor de revisar los datos de las referencías'
            });
        }
    }

    const onClickBtnApplyOperation = async () => {
        if (stateRefApartOrPayment === stateConfirmRefApartOrPayment) {
            
			const operationType = stateConfirmRefApartOrPayment.substring(0, 1);
			
			const refTmp = stateConfirmRefApartOrPayment.substring(1, stateConfirmRefApartOrPayment.length);
           
            if (operationType === Utils.OperationTypeEnum.PaymentA || operationType === Utils.OperationTypeEnum.PaymentB) {                   
                PaymentProcess(refTmp);
            } else {
                swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'las referencía no es para realizar un abono! --- Favor de revisar los datos de las referencías',
                    footer: 'Favor de revisar los datos de las referencías'
                });
            }
        } else {
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'las referencías no son iguales! --- Favor de revisar los datos de las referencías',
                footer: 'Favor de revisar los datos de las referencías'
              });
        }
    }


    const onClickBtnClean = () =>{
        setStateExpiration(new Date);
        setstateAmmount(0);
        setstatePayments(0);
        setstateBalance(0);      

        setStateSaleId(0);
        setstateDescrition('');
        setstateDetail('');
        setstateAmmountB(0);
    }

    const onClickBtnPrintTicket = () => {
        navigate(`/PosPymeTicketB/${stateResponseBoromirPayment.data?.cxcId}/${soid}/${id}`, { replace: true });
        //navigate(`/PosPymeTicketB/5487`, { replace: true });
    }

    const onClickBtnExit = () => {
        if (soid == 'Ex') {
            navigate('/SelOperation/' + id, { replace: true });
        }
        if (soid == 'Py') {
            navigate('/PosPymeIndex', { replace: true });
        }
        
    }


    /* common functions *//* common functions */
    const SearchPaymentProcess = async (refTmp) => {
        sethiddenSpinner1(true);
        let responseGetRef = await cxcService.getByIdWithBalance(refTmp); 
        
        if (responseGetRef.data !== null)
        {          
            const responseSale = await salesService.getById(auth.user.token, responseGetRef.data.ventaId);
            
            setStateExpiration(responseGetRef.data.fechaVencimiento);
            setstateAmmount(responseGetRef.data.valor);
            setstatePayments(responseGetRef.data.pagos);
            setstateBalance(responseGetRef.data.saldo);      

            setStateSaleId(responseSale.data.id);
            setstateDescrition(responseSale.data.description);
            setstateDetail(responseSale.data.details);
            setstateAmmountB(responseSale.data.ammount);

            setStateEnableBtnSearchRef('');
            setStateEnableBtnApplyRef('A');
            sethiddenSpinner1(false);
        } else {
            sethiddenSpinner1(false);
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'las referencías no son correctas',
                footer: 'Favor de crear una nueva referencía'
            });            
        }
    }

    const PaymentProcess = async (refTmp) => {        
        sethiddenSpinner1(true);
        const userForAuthenticate = {
            "User": "soporte@axify.mx",
            "Password": "Axify2023"
        };

        let responseUserBoromir = await boromirUserService.postAuthenticate(userForAuthenticate);

        const tokentoboromir = responseUserBoromir.token;

        let businnessidTemp = 0;
        if (soid == 'Ex') {
            businnessidTemp = id;
        }
        if (soid == 'Py') {
            businnessidTemp = `${auth.user.commerceList[0]}`;
        }

        const requestBoromirPayment =             {
            "referencia": stateConfirmRefApartOrPayment,
            "importe": stateAmmount,
            "compañia": `${auth.user.companyId}`,
            "sucursal": businnessidTemp,
            "idtransaccion" : uuidv4()
        };
        //console.log(requestBoromirPayment);
        let responseBoromirPaymentService = await boromirService.postProductPayment(tokentoboromir, requestBoromirPayment);
                
        //console.log(responseBoromirPaymentService);
        
        setstateResponseBoromirPayment(responseBoromirPaymentService);

        if(responseBoromirPaymentService.error.length == 0) {
            setstateSeeAlertSuccess(true);

            setStateEnableBtnSearchRef('');
            setStateEnableBtnApplyRef('');
            setStateEnableBtnPrintTicket('A');
        } else {
            setstateSeeAlertWarning(true);

            setStateEnableBtnSearchRef('');
            setStateEnableBtnApplyRef('');
            setStateEnableBtnPrintTicket('');
        }          
        sethiddenSpinner1(false);
    }

    return (
        <>
            <div className='row'>
                <div className='col'>
                    <h2>Aplicar Abonos</h2>
                </div>
                <div className='col'>
                    {
                        stateSeeAlertSuccess ? 
                            <div className="alert alert-success" role="alert">
                                {stateResponseBoromirPayment.data?.notas} FOLIO : {stateResponseBoromirPayment.data?.cxcId}
                            </div> : null}
                    {
                        stateSeeAlertWarning ?
                            <div className="alert alert-warning" role="alert">
                               {stateResponseBoromirPayment.error[0]?.description}
                            </div> : null}
                </div>
            </div>
            <br></br>
            <hr></hr>
            <div className='row'>
                <div className='col'>         
                    
                    <label htmlFor="inputRefApartOrPayment" className="form-label">Escribe o escanea la referencía</label>
                    <input id="inputRefApartOrPayment" className="form-control" placeholder="Referencia" 
                        onChange={onChangeRefApartOrPayment}                                
                        onKeyDown={onKeyDownRefApartOrPayment}  
                        value={stateRefApartOrPayment}     
                    ></input>                    
                    <div className='row'>
                        <div className='col'>
                            <button type="button" className="btn btn-primary btn-lg btn-block" 
                                onClick={onClickBtnSearchOperation}
                                disabled={!stateEnableBtnSearchRef}
                                >Buscar información de referencía</button>                    
                        </div>
                    </div>  
                    <label htmlFor="inputConfirmRefApartOrPayment" className="form-label">Confirma la referencía</label>
                    <input id="inputConfirmRefApartOrPayment" className="form-control" placeholder="confirma Referencia" 
                        onChange={onChangeConfirmRefApartOrPayment}                               
                        onKeyDown={onKeyDownConfirmRefApartOrPayment}  
                        value={stateConfirmRefApartOrPayment}     
                    ></input>                
                    <div className='row'>
                        <div className='col'>
                            <button type="button" className="btn btn-primary btn-lg btn-block" 
                            onClick={onClickBtnApplyOperation}
                            disabled={!stateEnableBtnApplyRef}
                            >Aplicar operación</button>
                        </div>
                    </div>        
                    <br></br>
                    <div className='row'>
                        <div className='col' style={{textAlign: "center"}}>
                            { hiddenSpinner1 &&
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>               
                </div>
                <div className='col'>
                    <label htmlFor="OperationId" className="form-label">Resumen de la operación</label>
                    <div className="mb-3 row">
                        <label for="inputCompanyId" className="col-sm-2 col-form-label">Vencimiento</label>
                        <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputCompanyId" value={stateExpiration}/>
                        </div>
                    </div>
                    
                    <div className="mb-3 row">
                        <label htmlFor="inputCompanyId" class="col-sm-2 col-form-label">Valor</label>
                        <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputCommerceId" value={stateAmmount}/>
                        </div>
                    </div>                                 
                    
                    <div className="mb-3 row">
                        <label htmlFor="inputImport" className="col-sm-2 col-form-label">Pagos</label>
                        <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputImport" value={statePayments}/>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="inputImportApart" className="col-sm-2 col-form-label">saldo</label>
                        <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputImportApart" value={stateBalance}/>
                        </div>
                    </div>                  
                                      
                    <div className='row'>
                        <div className='col'>
                        <table className='table'>
                            <tbody>                                
                                <tr>
                                    <td>Venta</td>
                                    <td style={{textAlign: "right"}}>{stateSaleid}</td>
                                </tr>                                
                                <tr>
                                    <td>Descripcion</td>
                                    <td style={{textAlign: "right"}}>{stateDescription}</td>
                                </tr>   
                                <tr>
                                    <td>Detalles</td>
                                    <td style={{textAlign: "right"}}>{stateDetail}</td>
                                </tr> 
                                <tr>
                                    <td>Importe</td>
                                    <td style={{textAlign: "right"}}>{stateAmmountB}</td>
                                </tr>                            
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div className='row'>
                       <div className='col'>
                            <button type="button" className="btn btn-primary btn-md" onClick={onClickBtnClean}>Limpiar</button>
                        </div>
                        <div className='col'>
                            <button type="button" className="btn btn-primary btn-md" 
                                onClick={onClickBtnPrintTicket}
                                disabled={!stateEnableBtnPrintTicket}
                            >Imprimir Ticket</button>                        
                        </div>
                        <div className='col' style={{textAlign: 'right'}}>
                            <button type="button" className="btn btn-danger btn-md" onClick={onClickBtnExit}>Salir</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default ServicePayment;
